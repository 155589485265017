<template>
  <div v-if="!loading">
    <v-card elevation="20" style="height: 100vh">
      <v-toolbar color="primary" class="text-white rounded-0" height="65px">
        <a @click.prevent="$router.go(-1)">
          <v-btn small class="mb-2" dark icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </a>
        <v-toolbar-title class="ml-3">
          <h3>{{ title }}</h3>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!--<router-link
          v-if="Number($route.params.id) != leads[0]?.id"
          :to="{
            name: 'lead-show',
            params: {
              id: Number($route.params.id) - 1,
            },
          }"
        >
          <v-btn class="primary" elevation="0">
            <v-icon left dark> mdi-arrow-left </v-icon>
            Önceki Lead
          </v-btn>
        </router-link>
        -->
        <router-link
          :to="{ name: 'user-edit', params: { id: getCurrentLead?.user_id } }"
        >
          <v-btn elevation="0" color="primary" v-if="getCurrentLead.user_id">
            Kullanıcıya Git
          </v-btn>
        </router-link>
        <!--
        <router-link
          v-if="Number($route.params.id) != leads[leads?.length - 1]?.id"
          :to="{
            name: 'lead-show',
            params: {
              id: Number($route.params.id) + 1,
            },
          }"
        >
          <v-btn class="primary" elevation="0"
            >Sonraki Lead
            <v-icon right dark> mdi-arrow-right </v-icon>
          </v-btn>
        </router-link>
        -->
        <v-spacer></v-spacer>
        <div>
          <v-menu v-model="menu" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="search"
                label="Lead Ara"
                dark
                @input="searchLead"
                style="width: 300px"
                hide-details="auto"
                single-line
                append-icon="search"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>

            <v-card>
              <v-list dense>
                <v-list-item-group color="primary">
                  <v-list-item
                    v-for="(lead, index) in filteredLeads"
                    :key="index"
                  >
                    <router-link
                      :to="{ name: 'lead-show', params: { id: lead?.id } }"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ lead.name }}</v-list-item-title>
                      </v-list-item-content>
                    </router-link>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </v-toolbar>
      <v-system-bar dark class="pa-4 text-white" color="indigo">
        <v-row justify="space-between" align="center">
          <v-col cols="3">
            <div v-if="getCurrentLead?.agent_id">
              <span class="text-lg-h6 text-md-body-1"
                ><b>Agent - </b>{{ getCurrentLead?.agent?.name }}</span
              >
              <v-dialog v-model="agentDialog" scrollable max-width="400px">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-1 mb-1" small v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <v-card>
                  <v-card-title class="mx-auto">Agent Atama</v-card-title>
                  <v-card-text style="height: 200px">
                    <v-list rounded max-height="200px">
                      <v-list-item
                        v-for="log in agentLogs"
                        :key="log?.id"
                        three-line
                        link
                        class="bg-light-danger mb-2"
                      >
                        <template v-slot:default>
                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>Lead Manager - </strong
                              >{{ log.causer.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <span class="text-primary">{{
                                returnLocaleDate(log.created_at)
                              }}</span>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle
                              v-if="log.properties.newValue"
                            >
                              {{
                                log.properties.oldValue
                              }}</v-list-item-subtitle
                            >
                            <v-list-item-subtitle
                              v-if="!log.properties.newValue"
                            >
                              <b> {{ log.properties.oldValue }} </b> agent
                              olarak atandı.
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-content-end">
                    <v-btn color="error" @click="agentDialog = false">
                      Kapat
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <v-col cols="4" class="d-flex justify-content-center">
            <v-dialog v-model="flowDialog" :max-width="1200">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="ml-1 mb-1" v-on="on" v-bind="attrs"
                  >mdi-help-circle-outline</v-icon
                >
              </template>
              <Card title="Lead Flow Diagram" modal>
                <template #close>
                  <v-btn icon @click="flowDialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <img
                  width="100%"
                  height="100%"
                  src="@/assets/images/lead_flow.png"
                />
              </Card>
            </v-dialog>
            <span class="text-lg-h6 text-md-body-1"
              ><b>Lead Durumu - </b
              >{{ getLeadStatusTranslate(getCurrentLead.status) }}</span
            >
            <v-dialog v-model="leadDialog" scrollable max-width="400px">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="ml-1 mb-1" v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <v-card>
                <v-card-title class="mx-auto">Lead Durum Akışı</v-card-title>
                <v-card-text style="height: 200px">
                  <v-list rounded max-height="200px">
                    <v-list-item
                      v-for="log in leadFlow"
                      :key="log?.id"
                      three-line
                      link
                      class="bg-light-danger mb-2"
                    >
                      <template v-slot:default>
                        <v-list-item-content>
                          <v-list-item-title>
                            <strong
                              >{{
                                log?.causer?.roles[0]?.description
                              }}
                              - </strong
                            >{{ log?.causer?.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <span class="text-primary">{{
                              returnLocaleDate(log?.created_at)
                            }}</span>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <b>Durum: </b> {{ log.properties.newValue }}
                            <v-icon color="error" small>mdi-arrow-right</v-icon>
                            {{ log.properties.oldValue }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions class="d-flex justify-content-end">
                  <v-btn color="error" @click="leadDialog = false">
                    Kapat
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <SendRegisterForm
              v-if="getCurrentLead?.status === 3"
              :lead_id="getCurrentLead?.id"
            />
            <SendVerifyEmailAgain
              v-if="getCurrentLead?.status === 4"
              :lead_id="getCurrentLead?.id"
            />
          </v-col>
          <v-col cols="4" class="d-flex justify-content-end">
            <SMAgentActionButtons :lead="getCurrentLead"></SMAgentActionButtons
          ></v-col>
        </v-row>
      </v-system-bar>
      <v-card-text class="pa-4">
        <v-row class="text-body-1" justify="space-between">
          <v-col cols="3" class="d-flex flex-column">
            <span><b>Kanal: </b>{{ getCurrentLead?.channel?.name }}</span>
            <span><b>Kampanya: </b>{{ getCurrentLead?.campaign?.name }}</span>
            <span>
              <b>İndirim Kodu: </b>
              {{ getCurrentLead?.discount_code }}
            </span>
          </v-col>
          <v-col cols="3" class="d-flex flex-column">
            <span>
              <b>E-Posta: </b>
              <a :href="'mailto:' + getCurrentLead?.email"
                >{{ getCurrentLead?.email }}
              </a>
            </span>
            <span>
              <b>Telefon: </b>
              <a :href="'tel:' + getCurrentLead?.phone"
                >{{ formatPhoneInput(getCurrentLead?.phone) }}
              </a>
            </span>
            <div v-if="getCurrentLead?.lead_result">
              <span
                ><b>Sonuç Kodu:</b>
                {{
                  getLeadResultCodeTranslate(
                    getCurrentLead.lead_result?.result_code_id
                  )
                }}</span
              >
            </div>
          </v-col>
          <v-col cols="3" class="d-flex flex-column">
            <span>
              <b>Meslek: </b>
              {{
                getCurrentLead?.job_title
                  ? getCurrentLead?.job_title
                  : "Belirtilmedi"
              }}
            </span>
            <span>
              <b>Neden Yazılımcı Olmak İstiyor: </b>
              {{
                getCurrentLead?.application_reason
                  ? getLeadApplicationReason(getCurrentLead?.application_reason)
                  : "Belirtilmedi"
              }}
            </span>
            <div v-if="getCurrentLead.lead_result">
              <span
                ><b>Alt Sonuç Kodu:</b>
                {{
                  getLeadResultCodeTranslate(
                    getCurrentLead.lead_result?.sub_result_code_id
                  )
                }}</span
              >
            </div>
          </v-col>
          <v-col cols="3" class="d-flex flex-column">
            <span
              ><b>Başvuru Tarihi: </b>
              {{ getCurrentLead?.application_date }}</span
            >
            <span
              ><b>İndirimli Ürün İnceleme: </b>
              {{
                getCurrentLead?.discounted_package_view
                  ? "İncelendi"
                  : "İncelenmedi"
              }}</span
            >
          </v-col>
        </v-row>
        <div v-if="getCurrentLead.google_lead">
          <v-row class="text-body-1" justify="space-between">
            <v-col cols="2" class="d-flex flex-column">
              <span><b>UTM Source: </b></span>
              <span>{{ getCurrentLead?.google_lead?.utm_source }}</span>
            </v-col>
            <v-col cols="2" class="d-flex flex-column">
              <span><b>UTM Medium: </b></span>
              <span>{{ getCurrentLead?.google_lead?.utm_medium }}</span>
            </v-col>
            <v-col cols="2" class="d-flex flex-column">
              <span><b>UTM Campaign: </b></span>
              <span>{{ getCurrentLead?.google_lead?.utm_campaign }}</span>
            </v-col>
            <v-col cols="2" class="d-flex flex-column">
              <span><b>UTM Term: </b></span>
              <span>{{ getCurrentLead?.google_lead?.utm_term }}</span>
            </v-col>
            <v-col cols="2" class="d-flex flex-column">
              <span><b>UTM Content: </b></span>
              <span>{{ getCurrentLead?.google_lead?.utm_content }}</span>
            </v-col>
          </v-row>
        </div>
        <div v-if="getCurrentLead.lead_from_funnel">
          <v-row
            class="text-body mt-3 bg-dark text-white p-1 px-3 font-size-lg"
          >
            <div>Lead Funnel Bilgileri</div>
          </v-row>
          <v-row class="text-body-1" justify="space-between">
            <v-col cols="2" class="d-flex flex-column">
              <span><b>Tecrübe: </b></span>
              <span>{{ getCurrentLead?.lead_from_funnel?.experience }}</span>
            </v-col>
            <v-col cols="2" class="d-flex flex-column">
              <span><b>Tecrübe Alanı: </b></span>
              <span>{{
                getCurrentLead?.lead_from_funnel?.experienced_area
              }}</span>
            </v-col>
            <v-col cols="2" class="d-flex flex-column">
              <span><b>Tercih ettiği haftalık ders süresi: </b></span>
              <span>{{
                getCurrentLead?.lead_from_funnel?.preferred_weekly_hours
              }}</span>
            </v-col>
            <v-col cols="2" class="d-flex flex-column">
              <span><b>Bütçe: </b></span>
              <span>{{ getCurrentLead?.lead_from_funnel?.budget }}</span>
            </v-col>
            <v-col cols="2" class="d-flex flex-column">
              <span><b>Tercih ettiği eğitim süresi </b></span>
              <span>{{
                getCurrentLead?.lead_from_funnel?.preferred_education_duration
              }}</span>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-system-bar
        dark
        class="pa-4 my-2 text-white"
        color="indigo"
        v-if="getCurrentLead.user"
      >
        <v-row class="text-body-1" justify="space-between">
          <v-col class="text-center">
            <span class="text-lg-h6 text-md-body-1 text-center">
              <b>Kullanıcı Bilgileri </b>
            </span>
          </v-col>
        </v-row>
      </v-system-bar>
      <v-row
        class="text-body-1 pa-4"
        justify="space-between"
        v-if="getCurrentLead.user"
      >
        <v-col cols="3" class="d-flex flex-column">
          <span><b>Adı: </b>{{ getCurrentLead?.user.name }}</span>
          <span class="d-flex align-items-center">
            <b class="text-nowrap mr-1">E-Posta: </b>
            <span
              v-if="getCurrentLead?.user?.emails?.length > 0"
              class="d-flex align-items-center"
            >
              <span class="mr-2 text-no-wrap">
                {{ primaryEmail(getCurrentLead) || "Email bulunamadı!" }}
              </span>

              <v-btn
                color="success"
                @click="openEmailModal('edit', getCurrentLead?.user)"
              >
                Kullanıcı E-posta Adresini Güncelle
              </v-btn>
            </span>
            <span v-else class="d-flex align-items-center">
              <span class="text-danger text-nowrap mr-5">
                Kullanının e-posta adresi mevcut değil!
              </span>
              <v-btn color="success" @click="openEmailModal('add')">
                Kullanıcıya E-posta Adresi Ekle
              </v-btn>
            </span>
          </span>
          <span>
            <b>Telefon: </b>
            {{ getCurrentLead?.user.phone }}
          </span>
        </v-col>

        <!--Email Modal-->
        <v-dialog v-model="emailModelFlag" max-width="500">
          <v-card>
            <v-toolbar color="primary" class="text-white">
              <v-toolbar-title>
                {{ mode === "edit" ? "E-posta Güncelle" : "E-posta Ekle" }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="emailModelFlag = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="pa-5">
              <v-form ref="form">
                <v-text-field
                  v-model="modalData.email"
                  label="E-posta Adresi"
                  required
                ></v-text-field>
                <v-btn color="primary" @click="saveEmailData(getCurrentLead)">{{
                  mode === "edit" ? "Güncelle" : "Ekle"
                }}</v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!--Modal End-->
      </v-row>
      <v-tabs v-model="tab" background-color="indigo" dark grow>
        <v-tabs-slider color="success"></v-tabs-slider>
        <v-tab
          class="d-flex justify-content-start"
          v-for="(item, index) in items"
          :key="index"
        >
          {{ item.text }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, index) in items" :key="index">
          <component
            :is="item.value"
            :logs="item.value === 'LogTable' ? logs : null"
            :pagination="item.value === 'LogTable' ? pagination : null"
            :showDescription="item.value === 'LogTable' ? true : false"
            @get-logs="getLogs($event)"
            v-bind="item.attrs"
            :leadName="getCurrentLead.name"
            :user_id="item.value === 'Offers' ? getCurrentLead?.user_id : null"
          ></component>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { GET_LEAD, FETCH_LEADS } from "@/core/services/store/leads.module";
import { isEmptyObject } from "jquery";

import { formatPhoneInput } from "@/core/helper.js";

import Notes from "@/view/content/notes/Notes.vue";
import NoteTable from "@/view/content/notes/NoteTable.vue";
import LogTable from "@/view/content/components/logs/LogTable.vue";
import Offers from "@/view/content/offers/Offers.vue";
import Card from "@/view/content/components/Card";
import SMAgentActionButtons from "@/view/content/siliconmade/SM-Agent-Action-Buttons.vue";
import SendRegisterForm from "@/view/content/components/SendRegisterForm.vue";
import SendVerifyEmailAgain from "@/view/content/components/SendVerifyEmailAgain.vue";
export default {
  components: {
    Card,
    Notes,
    SMAgentActionButtons,
    LogTable,
    NoteTable,
    Offers,
    SendRegisterForm,
    SendVerifyEmailAgain,
  },
  data() {
    return {
      emailModelFlag: false,
      mode: "",
      modalData: {
        email: "",
      },
      lead: {},
      search: "",
      open: false,
      loading: !!this.$route.params.id,
      flowDialog: false,
      items: [
        {
          value: "NoteTable",
          text: "Görüşmeler ve Notlar",
          attrs: {
            noteable_type: "App\\Models\\Lead",
            noteable_id: Number(this.$route.params.id),
            fromShowLeadPage: true,
          },
        },
        {
          value: "LogTable",
          text: "Aktivite Logları",
          attrs: {
            pagination: this.pagination,
            logs: this.logs,
            loading: false,
            showDescription: true,
          },
        },
        {
          value: "Offers",
          text: "Teklifler",
        },
      ],
      tab: null,
      pagination: {
        last_page: 0,
        per_page: 0,
        current_page: 1,
        total: 0,
      },
      logs: [],
      agentLogs: [],
      agentDialog: false,
      leadDialog: false,
      leadFlow: [],
      filteredLeads: [],
    };
  },
  async created() {
    await Promise.all([this.fetchResultCodes()]);
    await this.fetchLeads();
    this.loading = false;
  },
  mounted() {
    if (isEmptyObject(this.leads)) this.fetchLeads();

    this.filteredLeads = this.leads.slice(0, 5);
    if (this.$route.params.id) {
      this.getLead(Number(this.$route.params.id))
        .then(() => {
          this.getLogs();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  methods: {
    primaryEmail(getCurrentLead) {
      const primary = getCurrentLead?.user?.emails.find(
        (email) => email.is_primary === 1
      );
      return primary ? primary.email : null;
    },
    openEmailModal(mode, user = null) {
      this.mode = mode;
      if (mode === "edit" && user) {
        this.modalData.email = user.email?.email || "";
      } else {
        this.modalData.email = "";
      }
      this.emailModelFlag = true; //diaglog aciyor
    },
    saveEmailData(getCurrentLead) {
      const updatedEmail = this.modalData.email;
      if (this.mode === "edit") {
        this.$apiService
          .patch("email/updateUserEmail", {
            email: this.modalData.email,
            userId: getCurrentLead.user.id,
          })
          .then(() => {
            const primaryEmailObj = getCurrentLead.user.emails.find(
              (email) => email.is_primary === 1
            );
            if (primaryEmailObj) {
              primaryEmailObj.email = updatedEmail;
            }
            this.$toast.success(
              "Email güncellem işlemı başarıyle gerçekleştirildi",
              {
                duration: 2000,
                position: "bottom-right",
              }
            );
          })
          .catch(() => {
            this.$toast.error(
              "Email işlemı sırasında bir hata oluştu. Girdiğiniz email önceden mevcut olabilir",
              {
                duration: 2000,
                position: "bottom-right",
              }
            );
          });
      } else {
        this.$apiService
          .post("email/createUserEmail", {
            email: this.modalData.email,
            userId: getCurrentLead.user.id,
          })
          .then(() => {
            getCurrentLead.user.emails.push({
              email: updatedEmail,
              is_primary: 0,
            });

            this.$toast.success("Email işlemı başarıyle gerçekleştirildi", {
              duration: 2000,
              position: "bottom-right",
            });
          })
          .catch(() => {
            this.$toast.error(
              "Email işlemı sırasında bir hata oluştu. Girdiğiniz email önceden mevcut olabilir",
              {
                duration: 2000,
                position: "bottom-right",
              }
            );
          });
      }
      this.emailModelFlag = false;
    },
    ...mapActions([GET_LEAD, FETCH_LEADS, "fetchResultCodes"]),
    formatPhoneInput,
    getLogs(currentPage = this.pagination.current_page) {
      this.$apiService
        .get(`/logs/lead`, {
          params: {
            log_name: "lead",
            page: currentPage,
            subject_id: this.$route.params.id,
          },
        })
        .then(({ data }) => {
          this.pagination = {
            last_page: data.last_page,
            per_page: data.per_page,
            current_page: data.current_page,
            total: data.total,
          };
          this.logs = data.data;
          this.agentLogs = this.getAgentLogs();
          this.leadFlow = this.getLeadFlow();
        })
        .catch(() => {
          this.$toast.error(
            "İşlem kayıtları getirilirken bir hata oluştu. Lütfen yenile butonuna tıklayarak tekrar deneyiniz.",
            {
              duration: 2000,
              position: "bottom-right",
            }
          );
        });
    },
    getLeadResultCodeTranslate(resultCodeId) {
      return this.resultCodes.find((code) => code.id === resultCodeId)?.name;
    },
    searchLead() {
      this.filteredLeads = this.leads
        .filter((lead) => lead.name.includes(this.search))
        .slice(0, 5);
    },
    returnLocaleDate(date) {
      return this.$moment(date).format("DD MMMM YYYY HH:mm:ss");
    },
    getAgentLogs() {
      return this.logs.filter((log) => log.event === "Agent Atandı");
    },
    getLeadFlow() {
      return this.logs.filter((log) => log.event === "Lead Durumu Değişti");
    },
    getLeadStatusTranslate(status) {
      const leadStatuses = JSON.parse(localStorage.getItem("lead_statuses"));
      const translate = leadStatuses.find((s) => s.value === status).translate;
      switch (translate) {
        case "Teklif Taslağı Oluşturuldu":
          return "T.T.O.";
        case "Kullanıcı Kaydı Tamamlandı":
          return "K.K.T.";
        case "E-Posta Doğrulanması Bekleniyor":
          return "E.P.D.B.";
        default:
          return translate;
      }
    },
    getLeadApplicationReason(id) {
      const leadReasons = [
        { id: 1, text: "Yeni Meslek" },
        { id: 2, text: "Meslek Değiştirmek" },
        { id: 3, text: "Girişimci" },
        { id: 4, text: "Diğer" },
      ];
      return leadReasons.find((reason) => reason.id === id).text;
    },
  },
  computed: {
    ...mapGetters(["getCurrentLead", "leads", "resultCodes"]),
    title() {
      return "Lead " + " - " + this.getCurrentLead.name;
    },
    Agents() {
      const agents = JSON.parse(localStorage.getItem("agents"));
      return agents;
    },
  },
  watch: {
    agentDialog(val) {
      if (val) {
        this.getLogs();
      }
    },
    leadDialog(val) {
      if (val) {
        this.getLogs();
      }
    },
    tab(tab) {
      if (tab === 1) {
        this.getLogs();
      }
    },
  },
};
</script>

<style>
.user-management--disabled {
  pointer-events: none;
  opacity: 0.6;
  filter: grayscale(100%);
}
</style>
